import React from 'react'
import { Footer, Header, TechStack } from '../../components'
import profileImage from '../../assets/santhosh.png';
import './resume.scss'

const ResumePage = () => {
  return (
    <>
      <Header secondary />
      <div className="resume--container">
        <div className="resume-header-section">
          <div className="flex-container">
            <div className="left-side-section">
              <div className="name-heading">
                Santhosh Kumar Korimi
              </div>
              <div className="tagline-heading">
                Software Engineer with 7+ years of Experience
              </div>
              <div className="contact-info-flex-container">
                <div className="info-item">
                  <a href="callto:+919948241288">
                    <i className="fa fa-phone"></i>
                    +91 9948241288
                  </a>
                </div>
                <div className="info-item">
                  <a href="mailto:santhoshk.korimi@gmail.com">
                    <i className="fa fa-envelope"></i>
                    santhoshk.korimi@gmail.com
                  </a>
                </div>
              </div>
              <div className="contact-info-flex-container">
                <div className="info-item">
                  <a href="https://santhoshkorimi.com/">
                    <i className="fa fa-globe"></i>
                    https://santhoshkorimi.com/
                  </a>
                </div>
                <div className="info-item">
                  <a href="https://maps.app.goo.gl/16h1v7LgP9Wr4KGPA">
                    <i className="fa fa-map-marker"></i>
                    Hyderabad, Telangana
                  </a>
                </div>
              </div>
            </div>
            <div className="right-side-section">
              <div className="profile-image-section">
                <img src={profileImage} alt="Santhosh Kumar Korimi" />
              </div>
            </div>
          </div>
        </div>
        <div className="info-flex-container">
          <div className="left-side-section">
            <div className="summary-info-container">
              <div className="section-heading">
                Summary
              </div>
              <div className="summary-desc">
                Experienced Frontend Engineer with 7+ years in React.JS, excelling in pixel-perfect UIs. I translate design mockups into high-quality, performant applications, prioritizing user experience. My strong React.JS foundation ensures efficient, maintainable code. Committed to staying updated with the latest technologies, I thrive in collaborative, innovative environments.
              </div>
            </div>
            <div className="experience-info-container">
              <div className="section-heading">
                Professional Experience
              </div>
              <div className="experience-section">
                <div className="role-heading">
                  Team Lead - Frontend Development
                </div>
                <div className="company-name">
                  <a href="https://strobes.co/" noreferrer target="_blank">
                    Strobes Security, Inc.
                  </a>
                  <div className="time-frame">
                    Oct 2021 - Present
                  </div>
                </div>
                <div className="company-summary">
                  <ul>
                    <li>
                      Engineered an advanced data table component featuring field-level filtering capabilities.
                    </li>
                    <li>
                      Developed a query builder to facilitate the construction of search queries.
                    </li>
                    <li>
                      Successfully decreased build time from 18 to 5 minutes through precise configuration adjustments.
                    </li>
                    <li>
                      Conducted a seamless migration from Webpack to Vite.JS, enhancing development efficiency.
                    </li>
                    <li>
                      Designed and implemented a custom dashboards module allowing users to create and arrange charts within a grid layout.
                    </li>
                    <li>
                      Implemented a theming feature supporting four distinct themes within the application.
                    </li>
                    <li>
                      Provided leadership and guidance to a team of three members.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="experience-section">
                <div className="role-heading">
                  Senior MEAN Stack Developer
                </div>
                <div className="company-name">
                  <a href="https://finacplus.com/" noreferrer target="_blank">
                    FinacPlus
                  </a>
                  <div className="time-frame">
                    July 2019 - Sep 2021
                  </div>
                </div>
                <div className="company-summary">
                  <ul>
                    <li>
                      Designed and implemented the Reserved Seating Module, enabling the creation of efficient seating arrangements for event venues.
                    </li>
                    <li>
                      Migrated legacy application from .NET to Next.JS
                    </li>
                    <li>
                      Developed a white labeling solution for a website catering to numerous event venues
                    </li>
                  </ul>
                </div>
              </div>
              <div className="experience-section">
                <div className="role-heading">
                  Software Engineer
                </div>
                <div className="company-name">
                  <a href="https://charterglobal.com/" noreferrer target="_blank">
                    Charter Global
                  </a>
                  <div className="time-frame">
                    Oct 2018 - July 2019
                  </div>
                </div>
                <div className="company-summary">
                  <ul>
                    <li>
                      Designed and implemented a customized datepicker to meet the client's specifications, ensuring cross-browser compatibility and responsiveness across all screen sizes.
                    </li>
                    <li>
                      Executed a pixel-perfect UI based on the designer's mock-ups.
                    </li>
                    <li>
                      Created reusable components adaptable for both mobile and desktop platforms.
                    </li>
                    <li>
                      Actively participated in client meetings to gather requirements and provide updates on project progress.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="extra-text">

                +2 More
              </div>
            </div>
          </div>
          <div className="right-side-section">
            <div className="skills-section-container">
              <div className="section-heading">
                Top Skills
              </div>
              <TechStack isResume />
            </div>
            <div className="education-details-container">
              <div className="section-heading">
                Education
              </div>
              <div className="info-section">
                <div className="year">2016</div>
                <div className="course-name">
                  B.Tech in Computer Science and Engineering
                </div>
                <div className="college-name">
                  <a href="https://www.rgukt.ac.in/" target='_blank'>
                    RGUKT IIIT, Basar, Adilabad
                  </a>
                </div>
              </div>
              <div className="info-section">
                <div className="year">2012</div>
                <div className="course-name">
                  Pre-University Course (M.Bi.P.C)
                </div>
                <div className="college-name">
                  <a href="https://www.rgukt.ac.in/" target='_blank'>
                    RGUKT IIIT, Basar, Adilabad
                  </a>
                </div>
              </div>
            </div>
            <div className="certifications-section">
              <div className="section-heading">
                Certifications
              </div>
              <ul>
                <li className="certificate-info-section">
                  <div className="name">
                    JavaScript Essential Training
                  </div>
                  <div className="source">
                    <a href="https://www.linkedin.com/learning/certificates/48f1668a19802602ef5f12963edc01a71cadb12b3fb8397b3ee232c8a2bc0615?trk=backfilled_certificate">
                      Linkedin Learning
                    </a>
                  </div>
                </li>
                <li className="certificate-info-section">
                  <div className="name">
                    TypeScript Essential Training
                  </div>
                  <div className="source">
                    <a href="https://www.linkedin.com/learning/certificates/b4e88b343c7daa0a532430433dbfe0485c7dd0b9a2589d96fca676908d603e58?trk=backfilled_certificate">
                      Linkedin Learning
                    </a>
                  </div>
                </li>
                <li className="certificate-info-section">
                  <div className="name">
                    React: Using TypeScript
                  </div>
                  <div className="source">
                    <a href="https://www.linkedin.com/learning/certificates/f886caf6fe559464985d5c1ae8daa279e742a32ec91c2f18a3179dfc4ab836e8?trk=backfilled_certificate">
                      Linkedin Learning
                    </a>
                  </div>
                </li>
                <li className="certificate-info-section">
                  <div className="name">
                    TypeScript: Object-Oriented Programming
                  </div>
                  <div className="source">
                    <a href="https://www.linkedin.com/learning/certificates/86165693c0bb498917b3d857e65bc31835214f3214c68e52fa5102cefed72f4f?trk=backfilled_certificate">
                      Linkedin Learning
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="certifications-section languages-section">
              <div className="section-heading">
                Languages
              </div>
              <div className="tags-container">
                <div className="tag">English</div>
                <div className="tag">Hindi</div>
                <div className="tag">Telugu</div>
              </div>
            </div>
            <div className="declaration-section">
              <div className="section-heading">
                Declartion
              </div>
              <div className="section-desc">
                I affirm that the information provided above is truthful and accurate to the best of my knowledge.
              </div>
              <div className="sign-name-text">
                Santhosh Kumar Korimi
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ResumePage
