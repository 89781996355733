import React from 'react'
import { DevCard } from '../../components'

const DevCardPage = () => {
  return (
    <div className="dev-card-app">
      <DevCard />
    </div>
  )
}

export default DevCardPage